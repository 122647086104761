<template>
    <div class="job_news">
        <div class="container">
          <div class="row">

              <div id="job_news_tabs">
                <Tabs type="card" v-model="ajiliinBair">
                      <TabPane :label="'baiguullagin_ajliin_bairnii_zar' | translate " name="baiguullagin_ajliin_bairnii_zar">
                        <baiguullaga_ajil_bair_zar></baiguullaga_ajil_bair_zar>
                      </TabPane>
                      <TabPane :label="'brigad_ajliin_bairnii_zar' | translate " name="brigad_ajliin_bairnii_zar">
                        <brigad_ajil_bair_zar></brigad_ajil_bair_zar>
                      </TabPane>
                    </Tabs>
              </div>
            </div>

        </div>
    </div>
</template>

<script>

import {mapGetters} from "vuex";
const brigad_ajil_bair_zar = () => import(/* webpackChunkName: "brigad_ajiliin_bairnii_zar" */ './brigad_ajiliin_bairnii_zar');
const baiguullaga_ajil_bair_zar = () => import(/* webpackChunkName: "baiguullaga_ajiliin_bairni_zar" */ './baiguullaga_ajiliin_bairni_zar');
export default {
    name: "ajliin_bairnii_zar",
    components: {
      "baiguullaga_ajil_bair_zar": baiguullaga_ajil_bair_zar,
      "brigad_ajil_bair_zar": brigad_ajil_bair_zar,
    },
    data() {
        return {
          ajiliinBair: 'baiguullagin_ajliin_bairnii_zar',
        }
    },
    computed: {
        ...mapGetters([
            'languages',
            'language',
            // ...
        ]),
    },
    mounted() {

    },
    methods: {
        // handleReachBottom() {
        //     return new Promise(resolve => {
        //         setTimeout(() => {
        //             const last = this.list1[this.list1.length - 1];
        //             for (let i = 1; i < 11; i++) {
        //                 this.list1.push(last + i);
        //             }
        //             resolve();
        //         }, 2000);
        //     });
        // },
    }


}
</script>

<style scoped>

</style>



